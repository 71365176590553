import { SCALE_RATIO } from './constants';
import { getRandomColor } from './colorHelpers';
import { getRandomLeftTop } from './canvasHelpers';

const DEFAULT_TEXT = 'Văn bản mới';

const getDefaultFontSize = ({ zoomCount }) => {
  return 18 * Math.pow(SCALE_RATIO, zoomCount);
};

export const addText = ({
  canvas,
  fontFamily,
  fontSize,
  text = DEFAULT_TEXT,
  zoomCount = 0
}) => {
  const defaultFontSize = getDefaultFontSize({ zoomCount });
  const textSample = new fabric.IText(text, {
    fontFamily,
    fill: `#${getRandomColor()}`,
    fontSize: fontSize ? fontSize : defaultFontSize
  });
  const coord = getRandomLeftTop({ canvas, obj: textSample });
  const { top, left } = coord;

  textSample.set({ left, top });

  canvas.add(textSample);
};
