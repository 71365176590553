<template>
  <div id="app">
    <div
      id="dpi"
      style="height: 1cm; width: 1cm; left: 100%; position: fixed; top: 100%"
    ></div>
    <div
      v-if="isLoading"
      class="
        align-items-center
        d-flex
        h-100
        justify-content-center
        min-vh-100
        w-100
        flex-column
      "
    >
      <lottie-player
        src="https://assets9.lottiefiles.com/temp/lf20_zeE847.json"
        mode="bounce"
        background="transparent"
        speed="2"
        style="width: 300px; height: 300px"
        loop
        autoplay
      ></lottie-player>
      <div class="w-100">Đang tải dữ liệu...</div>
    </div>
    <router-view v-if="!isLoading" />

    <!-- <b-modal id="signInModal" title="Sign In" size="sm" hide-footer>
      <section
        id="firebaseui-auth-container"
        class="firebase-container"
      ></section>
    </b-modal> -->
  </div>
</template>

<style scoped>
@import url('https://fonts.googleapis.com/css?family=Muli:300,400,500,600,700,800,900&display=swap');

/deep/ .firebase-container {
  min-height: 150px;
}
</style>

<script>
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebaseui/dist/firebaseui.css';
const firebaseui = require('firebaseui');

import * as Sentry from '@sentry/vue';
import { BModal } from 'bootstrap-vue';
import { RequestFactory } from '@Request/RequestFactory';
import { SET_PRODUCT_CATEGORY_LIST, SET_WINDOW_SIZE } from '@/store/app';
const InformationRequest = RequestFactory.get('information');
const ProductRequest = RequestFactory.get('products');

export default {
  name: 'App',
  components: {
    BModal
  },
  data() {
    return {
      isLoading: true,
      menus: [],
      websiteConfig: [],
      currencies: [],
      categories: [],
      firebaseLoading: true
    };
  },
  created() {
    this.fetchAllData();
  },
  methods: {
    async fetchInformation() {
      try {
        const {
          data: { response }
        } = await InformationRequest.getSetting();
        const { menus, websiteConfig, currencies, categories } = response;

        this.menus = menus;
        this.websiteConfig = websiteConfig;
        this.currencies = currencies;
        this.categories = categories;
      } catch (error) {
        Sentry.captureException(error);
      }
    },
    async fetchProductCategory() {
      try {
        const store = this.$store;
        const {
          data: { response }
        } = await ProductRequest.getCategories();

        store.commit(`app/${SET_PRODUCT_CATEGORY_LIST}`, {
          productCategoryList: response
        });
      } catch (error) {
        Sentry.captureException(error);
      }
    },
    async fetchAllData() {
      this.isLoading = true;
      // await this.fetchInformation();
      await this.fetchProductCategory();

      this.isLoading = false;
    },
    onResize() {
      this.$store.commit(`app/${SET_WINDOW_SIZE}`, {
        width: window.innerWidth,
        height: window.innerHeight
      });
    },
    unload($event) {
      $event.preventDefault();
      $event.returnValue = '';
    }
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.unload);
  },
  mounted() {
    const _this = this;
    const dpiElement = document.getElementById('dpi');

    window.CM_TO_PX = dpiElement.offsetWidth;
    dpiElement.remove();

    _this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });

    /* _this.$root.$on('bv::modal::shown', (bvEvent, modalId) => {
      if (modalId === 'signInModal') {
        let ui = firebaseui.auth.AuthUI.getInstance();

        const uiConfig = {
          callbacks: {
            signInSuccessWithAuthResult(authResult, redirectUrl) {
              // User successfully signed in.
              // Return type determines whether we continue the redirect automatically
              // or whether we leave that to developer to handle.

              // https://bootstrap-vue.org/docs/components/modal#
              _this.$root.$emit('bv::hide::modal', 'signInModal');
              return false;
            },
            uiShown() {
              // The widget is rendered.
              // Hide the loader.
              // document.getElementById('loader').style.display = 'none';
            },
          },
          // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
          signInFlow: 'popup',
          // signInSuccessUrl: '/',
          signInOptions: [
            // Leave the lines as is for the providers you want to offer your users.
            firebase.auth.EmailAuthProvider.PROVIDER_ID,
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            firebase.auth.FacebookAuthProvider.PROVIDER_ID,
            // firebase.auth.TwitterAuthProvider.PROVIDER_ID,
            // firebase.auth.GithubAuthProvider.PROVIDER_ID,
            // firebase.auth.PhoneAuthProvider.PROVIDER_ID,
          ],
        };

        if (!ui) {
          ui = new firebaseui.auth.AuthUI(firebase.auth());
        }

        // if (ui.isPendingRedirect()) {
        ui.start('#firebaseui-auth-container', uiConfig);
        // }
      }
    }); */
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
    window.removeEventListener('beforeunload', this.unload);
  }
};
</script>
