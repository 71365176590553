import { commonUtils, canvasHelpers, elementHelpers } from '@/utils';

const defaultCanvasId = commonUtils.genUniqueId();
const defaultActiveObjectStyle = {
  fontFamily: '',
  fontSize: '',
  fill: '',
  stroke: '',
  textAlign: '',
  fontStyle: {
    bold: false,
    italic: false,
    underline: false
  },
  opacity: ''
};

const shapeList = [
  {
    name: 'circle',
    label: 'Hình tròn'
  },
  {
    name: 'square',
    label: 'Hình vuông'
  },
  {
    name: 'triangle',
    label: 'Tam giác'
  }
];

/**
 * All actions for fetching data from server
 */
export const SET_FONT_LIST = 'SET_FONT_LIST';

/**
 * All runtime actions
 */
export const CHANGE_PRODUCT = 'CHANGE_PRODUCT';

export const SET_CANVAS_LIST = 'SET_CANVAS_LIST';
export const SET_ACTIVE_CANVAS = 'SET_ACTIVE_CANVAS';
export const SET_CANVAS = 'SET_CANVAS';
export const ADD_CANVAS = 'ADD_CANVAS';
export const SET_ACTIVE_OBJECT_STYLE = 'SET_ACTIVE_OBJECT_STYLE';
export const SET_HAS_ACTIVE_OBJECT = 'SET_HAS_ACTIVE_OBJECT';
export const SET_HAS_ACTIVE_OBJECT_TEXT = 'SET_HAS_ACTIVE_OBJECT_TEXT';
export const RESET_ANY_ACTIVE = 'RESET_ANY_ACTIVE';
export const REMOVE_CANVAS = 'REMOVE_CANVAS';
export const SET_CURRENT_RATIO = 'SET_CURRENT_RATIO';
export const SET_ACTIVE_ITEM_BY_PRODUCT_ID = 'SET_ACTIVE_ITEM_BY_PRODUCT_ID';
export const SET_APP_ELEMENT = 'SET_APP_ELEMENT';
export const SET_SUB_MENU_SHAPE = 'SET_SUB_MENU_SHAPE';
export const SET_SUB_MENU_ICON = 'SET_SUB_MENU_ICON';
export const SET_SUB_MENU_CARTOON = 'SET_SUB_MENU_CARTOON';
export const SET_SUB_MENU_IMAGE = 'SET_SUB_MENU_IMAGE';

export const SET_IS_REDO_DISABLED = 'SET_IS_REDO_DISABLED';
export const INCREASE_UNDO_COUNT = 'INCREASE_UNDO_COUNT';
export const STATUS_EDIT_CANVAS_MB = 'STATUS_EDIT_CANVAS_MB';
export const ACTIVE_CANVAS_ID = 'ACTIVE_CANVAS_ID';
export const SET_OBJECT_COPY_CANVAS = 'SET_OBJECT_COPY_CANVAS';

export default {
  namespaced: true,
  state: () => ({
    appElement: null,
    subMenuShape: {
      categoryId: null,
      name: null,
      currentPage: null,
      perPage: null,
      totalRows: null,
      shapeList: []
    },
    subMenuIcon: {
      categoryId: null,
      name: null,
      currentPage: null,
      perPage: null,
      totalRows: null,
      iconList: []
    },
    subMenuCartoon: {
      categoryId: null,
      name: null,
      currentPage: null,
      perPage: null,
      totalRows: null,
      cartoonList: []
    },
    subMenuImage: {
      categoryId: null,
      name: null,
      currentPage: null,
      perPage: null,
      totalRows: null,
      imageList: []
    },
    appData: {
      fontList: [],
      shapeList
    },
    canvasList: [
      {
        id: defaultCanvasId,
        svgURL: '',
        canvas: null,
        templateId: ''
      }
    ],
    activeCanvas: null,
    activeProductId: '',
    activeItemByProductId: {},
    activeCanvasId: defaultCanvasId,
    activeRatio: {},
    activeZoom: 0,
    activeObjectStyle: { ...defaultActiveObjectStyle },
    hasActiveObject: false,
    hasActiveObjectText: false,
    actionCount: 0, // For undo/redo
    undoCount: 0, // For undo/redo
    isRedoDisabled: true,
    firstName: 'Norman', // testing purpose only
    statusEditCanvas: false, // edit mode mb
    pick2nd: true, //pick in mb
    objectCopyCanvas: null //copy object
  }),
  mutations: {
    /**
     * All "data from server" mutations
     */
    [SET_FONT_LIST](state, { fontList }) {
      state.appData = {
        ...state.appData,
        fontList
      };
    },

    [SET_ACTIVE_ITEM_BY_PRODUCT_ID](state, data) {
      state.activeItemByProductId = data;
    },
    /**
     * All runtime mutations
     */
    [SET_ACTIVE_CANVAS](state, payload) {
      state.activeCanvas = payload.canvas;
      state.activeCanvasId = payload.id;
    },
    [ACTIVE_CANVAS_ID](state, payload) {
      state.activeCanvasId = payload;
    },
    [SET_CANVAS](state, payload) {
      const { canvasId, canvas } = payload;

      state.canvasList = state.canvasList.map(c => {
        if (c.id === canvasId) {
          return {
            ...c,
            canvas
          };
        }

        return c;
      });
    },
    [ADD_CANVAS](state, payload) {
      const { canvasData } = payload;

      state.canvasList = [...state.canvasList, canvasData];
    },
    [REMOVE_CANVAS](state, payload) {
      const { canvasId } = payload;

      state.canvasList = state.canvasList.filter(c => c.id !== canvasId);
    },
    [SET_CANVAS_LIST](state, payload) {
      const { canvasList } = payload;

      state.canvasList = canvasList;
    },
    [SET_CURRENT_RATIO](state, payload) {
      const { activeRatio } = payload;

      state.activeRatio = activeRatio;
    },
    [SET_HAS_ACTIVE_OBJECT](state) {
      state.hasActiveObject = true;
      state.hasActiveObjectText = false;
      state.statusEditCanvas = false;
    },
    [SET_HAS_ACTIVE_OBJECT_TEXT](state) {
      state.hasActiveObject = false;
      state.hasActiveObjectText = true;
      state.statusEditCanvas = false;
    },
    [RESET_ANY_ACTIVE](state) {
      state.hasActiveObject = false;
      state.hasActiveObjectText = false;
    },
    [SET_ACTIVE_OBJECT_STYLE](state, payload) {
      const { activeObjectStyle } = payload;

      state.activeObjectStyle = activeObjectStyle;
    },
    [CHANGE_PRODUCT](state, payload) {
      const { activeProductId, activeRatio } = payload;

      state.activeProductId = activeProductId;
      state.activeRatio = activeRatio;
      state.canvasList = state.canvasList.filter(
        c => c.id === state.activeCanvasId
      );
    },
    [INCREASE_UNDO_COUNT](state) {
      state.actionCount = state.actionCount + 1;
      state.undoCount = state.undoCount + 1;
    },
    [STATUS_EDIT_CANVAS_MB](state, value) {
      if (state.hasActiveObjectText) {
        state.statusEditCanvas = value;
      }
    },
    [SET_IS_REDO_DISABLED](state, value) {
      state.isRedoDisabled = value;
    },
    [SET_APP_ELEMENT](state, payload) {
      state.appElement = payload;
    },
    [SET_SUB_MENU_SHAPE](state, payload) {
      state.subMenuShape = payload;
    },
    [SET_SUB_MENU_ICON](state, payload) {
      state.subMenuIcon = payload;
    },
    [SET_SUB_MENU_CARTOON](state, payload) {
      state.subMenuCartoon = payload;
    },
    [SET_SUB_MENU_IMAGE](state, payload) {
      state.subMenuImage = payload;
    },
    [SET_OBJECT_COPY_CANVAS](state, payload) {
      state.objectCopyCanvas = payload;
    }
  },
  getters: {
    activeProduct(state, getters, rootState) {
      const { app } = rootState;
      const { activeProductId } = state;
      const { productList = [] } = app || {};

      return productList.find(p => p.id === activeProductId);
    },
    nameCategory(state, getters, rootState) {
      const { app } = rootState;
      const { activeProductId } = state;
      const { productCategoryList = [] } = app || {};

      return productCategoryList.find(p => p.id === activeProductId);
    },
    selectedItem(state) {
      const { activeCanvas } = state;

      if (activeCanvas) {
        return activeCanvas.getActiveObject();
      }
    },
    fullName: state => {
      // testing purpose only
      return `${state.firstName} Wisley`;
    },
    getCanvasList: state => {
      return state.canvasList;
    },
    getStatusEditCanvas: state => {
      return state.statusEditCanvas;
    }
  },
  actions: {
    setActiveObject({ commit }, payload) {
      const { object } = payload;
      const activeObjectStyle = elementHelpers.buildActiveObjectStyle(object);

      if (!object) {
        return;
      }

      const { type } = object;

      if (type === 'i-text') {
        commit(SET_HAS_ACTIVE_OBJECT_TEXT);
      } else {
        object.setControlsVisibility({
          edit: false
        });
        commit(SET_HAS_ACTIVE_OBJECT);
      }

      commit(SET_ACTIVE_OBJECT_STYLE, {
        activeObjectStyle
      });
    },
    resetActiveObject({ commit }) {
      commit(RESET_ANY_ACTIVE);
      commit(SET_ACTIVE_OBJECT_STYLE, {
        activeObjectStyle: { ...defaultActiveObjectStyle }
      });
    },
    updateActiveObjectStyle({ commit, state }, payload) {
      // TODO
      /**
       * - Update state.activeObjectStyle
       * - Update selected object style in active canvas
       * - Re-render active canvas
       */
      const { activeCanvas, activeObjectStyle } = state;
      const { name, value } = payload;

      if (!activeCanvas) {
        return;
      }

      let finalValue = value;

      if (name === 'fontStyle') {
        const { fontStyle } = activeObjectStyle;
        const acceptedValues = ['bold', 'italic', 'underline'];

        if (acceptedValues.indexOf(value) === -1) {
          return;
        }

        finalValue = {
          ...fontStyle,
          [value]: !fontStyle[value]
        };
      }

      commit(SET_ACTIVE_OBJECT_STYLE, {
        activeObjectStyle: {
          ...activeObjectStyle,
          [name]: finalValue
        }
      });

      const object = activeCanvas.getActiveObject();
      elementHelpers.setActiveObjectStyle({
        object,
        styleName: name,
        value
      });

      object.setCoords();

      activeCanvas.renderAll();
    },
    setObjectCopy({ commit, state }) {
      const canvas = state.activeCanvas;
      if (canvas && canvas.getActiveObject()) {
        var object = fabric.util.object.clone(canvas.getActiveObject());
        object.set('top', object.top + 5);
        object.set('left', object.left + 5);
        commit(SET_OBJECT_COPY_CANVAS, object);
      }
    },
    setObjectPaste({ commit, state }) {
      const canvas = state.activeCanvas;
      const objectCopy = state.objectCopyCanvas;
      if (canvas && objectCopy) {
        canvas.discardActiveObject();
        var object = fabric.util.object.clone(objectCopy);
        object.set('top', object.top + 5);
        object.set('left', object.left + 5);
        canvas.add(object);
        //active canvas
        canvas.setActiveObject(object);
        canvas.requestRenderAll();

        var objectAfterCopy = fabric.util.object.clone(objectCopy);
        objectAfterCopy.set('top', objectAfterCopy.top + 5);
        objectAfterCopy.set('left', objectAfterCopy.left + 5);
        commit(SET_OBJECT_COPY_CANVAS, objectAfterCopy);
      }
    },
    initCanvas({ commit, dispatch, state, rootState }, payload) {
      /**
       * Requirements for this action
       * - Make sure canvas existed in DOM (usually use in $nextTick in any component)
       * - activeRatio in the store
       */
      const { activeRatio, activeCanvasId } = state;
      const { id, svgURL = '' } = payload;
      let checkMobile = commonUtils.isMobile({ state: rootState });

      if (!activeRatio.ratioWidth || !activeRatio.ratioHeight) {
        return;
      }

      const onMouseDown = ({ id, canvas }) => opt => {
        if (
          opt.target &&
          opt.target.type === 'i-text' &&
          checkMobile &&
          !state.statusEditCanvas
        )
          opt.target.editable = false;

        if (!opt.target) {
          dispatch('resetActiveObject');
        }
        //lock object with mb
        if (!state.pick2nd && opt.target) {
          opt.target.lockMovementX = true;
          opt.target.lockMovementY = true;
          opt.target.lockScalingX = true;
          opt.target.lockScalingY = true;
          opt.target.lockRotation = true;
          state.pick2nd = true;
        } else if (opt.target) {
          opt.target.lockMovementX = false;
          opt.target.lockMovementY = false;
          opt.target.lockScalingX = false;
          opt.target.lockScalingY = false;
          opt.target.lockRotation = false;
        }
        commit(SET_ACTIVE_CANVAS, {
          id,
          canvas
        });
      };

      const onDblClick = ({ id, canvas }) => opt => {
        if (opt.target && opt.target.type === 'i-text' && checkMobile)
          opt.target.editable = false;
      };

      const onSelectObject = ({ target: object }) => {
        if (checkMobile) {
          state.pick2nd = false;
        }
        dispatch('setActiveObject', {
          object
        });
      };
      const onKeydown = canvas => e => {
        if (canvas) {
          const { keyCode } = e;

          if (keyCode === 8 || keyCode === 46) {
            // Backspace or Delete
            const activeObject = canvas.getActiveObject();

            if (activeObject) {
              canvas.remove(activeObject);
              dispatch('resetActiveObject');
            }
          }

          if (e.ctrlKey && keyCode == 90) {
            // CTRL+Z
            dispatch('handleUndo');
          }

          if (e.ctrlKey && keyCode == 89) {
            // CTRL+Y
            dispatch('handleRedo');
          }
        }
      };

      const onCopyObject = () => {
        const canvas = state.activeCanvas;
        if (canvas && canvas.getActiveObject()) {
          var object = fabric.util.object.clone(canvas.getActiveObject());
          object.set('top', object.top + 5);
          object.set('left', object.left + 5);
          commit(SET_OBJECT_COPY_CANVAS, object);
        }
      };

      const onPasteObject = () => {
        const canvas = state.activeCanvas;
        const objectCopy = state.objectCopyCanvas;
        if (canvas && objectCopy) {
          canvas.discardActiveObject();
          var object = fabric.util.object.clone(objectCopy);
          object.set('top', object.top + 5);
          object.set('left', object.left + 5);
          canvas.add(object);
          //active canvas
          canvas.setActiveObject(object);
          canvas.requestRenderAll();

          var objectAfterCopy = fabric.util.object.clone(objectCopy);
          objectAfterCopy.set('top', objectAfterCopy.top + 5);
          objectAfterCopy.set('left', objectAfterCopy.left + 5);
          commit(SET_OBJECT_COPY_CANVAS, objectAfterCopy);
        }
      };

      const initUndoRedo = () => {
        commit(INCREASE_UNDO_COUNT);
      };

      const updateStatusEditMb = value => {
        commit(STATUS_EDIT_CANVAS_MB, value);
      };

      const canvas = canvasHelpers.initCanvas({
        id,
        ratioData: activeRatio,
        events: {
          onMouseDown,
          onDblClick,
          onSelectObject,
          onKeydown,
          onCopyObject,
          onPasteObject,
          initUndoRedo,
          updateStatusEditMb
        },
        checkMobile
      });

      if (svgURL) {
        canvasHelpers.loadSVG({
          canvas,
          url: svgURL
        });
      }

      commit(SET_CANVAS, {
        canvasId: id,
        canvas
      });

      if (activeCanvasId === id) {
        commit(SET_ACTIVE_CANVAS, {
          id,
          canvas
        });
      }
    },
    initAllCanvas({ commit, dispatch, state }) {
      /**
       * Init all canvas in canvasList
       * Used when first init page
       * */
      const { canvasList, activeRatio, activeCanvasId } = state;
      const canvasListLength = canvasList.length;

      if (!activeRatio.ratioWidth || !activeRatio.ratioHeight) {
        return;
      }

      if (!activeCanvasId && canvasList.length > 0) {
        commit(SET_ACTIVE_CANVAS, {
          id: canvasList[0].id,
          canvas: null
        });
      }

      for (let j = 0; j < canvasListLength; j++) {
        const canvasData = canvasList[j];
        const { id, svgURL } = canvasData;

        dispatch('initCanvas', { id, svgURL });
      }
    },
    clearActiveCanvasState({ commit, dispatch, state }) {
      const { activeCanvas } = state;

      activeCanvas.discardActiveObject().requestRenderAll();

      commit(SET_ACTIVE_CANVAS, {
        id: '',
        canvas: null
      });
      dispatch('resetActiveObject');
    },
    enableRedo({ commit }) {
      commit(SET_IS_REDO_DISABLED, false);
    },
    disableRedo({ commit }) {
      commit(SET_IS_REDO_DISABLED, true);
    },
    handleUndo({ dispatch, state }) {
      const { activeCanvas } = state;

      if (activeCanvas) {
        activeCanvas.undo();

        dispatch('enableRedo');
      }
    },
    handleRedo({ dispatch, state }) {
      const { activeCanvas } = state;

      if (activeCanvas) {
        activeCanvas.redo();

        dispatch('enableRedo');
      }
    }
  }
};
