import { fabric } from 'fabric';
import {
  deleteIcon,
  cloneIcon,
  editIcon,
  copyIcon,
  pasteIcon
} from './mediaData';

export const getRandomLeftTop = ({ canvas, obj }) => {
  const offset = 50;
  const { getRandomInt } = fabric.util;

  return {
    left: getRandomInt(0 + offset, canvas.getWidth() - obj.width),
    top: getRandomInt(0 + offset, canvas.getHeight() - obj.height)
  };
};

export const centerCanvasRotate = ({ canvas, deg }) => {
  const newWidth = canvas.getHeight();
  const newHeight = canvas.getWidth();
  let backup;

  canvas.getObjects().forEach(function(obj) {
    const oldTop = obj.top;
    const oldLeft = obj.left;
    obj.top = deg < 0 ? newHeight - oldLeft : oldLeft;
    obj.left = deg < 0 ? oldTop : newWidth - oldTop;
    obj.angle += deg;
  });

  backup = canvas.toObject();

  canvas.setDimensions({
    width: newWidth,
    height: newHeight
  });

  canvas.loadFromJSON(backup);
};

/**
 * Convert an fabric.Object item to fabric.IText
 * @param {fabric.Object} obj
 * @returns {fabric.IText}
 */
const ConvertToIText = obj => {
  const text = obj.text;
  const textobj = obj.toObject();

  textobj.fontFamily = textobj.fontFamily.replaceAll("'", '');

  // console.log('ConvertToIText, text, textobj', text, textobj);

  delete textobj.text;
  delete textobj.type;

  const itext = new fabric.IText(text, textobj);
  itext.styles = {};

  return itext;
};

/**
 * Clear all objects and restore default background color
 * @param {object} canvas
 * @returns {void}
 */
export const clearCanvas = ({ canvas }) => {
  canvas.clear();

  canvas.backgroundColor = '#FFF';
};

/**
 * Load SVG into a canvas
 *
 * @param {string} url  Absolute url of SVG file
 * @param {object} canvas canvas for loading SVG to
 * @returns {void}
 */
export const loadSVG = ({ url, canvas }) => {
  fabric.loadSVGFromURL(url, function(objects, options) {
    if (!objects) {
      // Invalid SVG file
      return false;
    }

    clearCanvas({ canvas });
    const fontArray = [];

    /**
     * Convert all text objects to IText (editable)
     */
    const newObjects = objects.map(obj => {
      let newObj;
      if (obj.type == 'text') {
        fontArray.push(obj.fontFamily);

        newObj = ConvertToIText(obj);
        newObj.firstText = obj.text;
        newObj.textState = 'original';

        return newObj;
      }

      return obj;
    });

    console.log('Danh sách font trong mẫu');
    console.log(
      fontArray.filter((value, index, self) => self.indexOf(value) === index)
    );

    /**
     * Group all objects into one for scaling
     */
    const svg = fabric.util.groupSVGElements(newObjects, options);

    const oldCanvasWidth = canvas.width;
    const oldCanvasHeight = canvas.height;

    const widthRatio = oldCanvasWidth / svg.width;
    const heightRatio = oldCanvasHeight / svg.height;
    const ratio = Math.min(widthRatio, heightRatio);

    /**
     * Scale SVG image to maximum as it can
     */
    svg.scaleToWidth(svg.width * ratio);

    /**
     * Ungroup all objects
     */
    svg._restoreObjectsState();

    const items = svg._objects;

    for (let index = 0; index < items.length; index++) {
      const element = items[index];
      canvas.add(element);
    }

    canvas.backgroundColor = '#FFF';
    canvas.requestRenderAll();
  });
};

export const addSVGContainerOneItem = ({ url, canvas }) => {
  fabric.loadSVGFromURL(url, function(objects, options) {
    if (!objects) {
      // Invalid SVG file
      return false;
    }
    const svg = fabric.util.groupSVGElements(objects, options);
    const oldCanvasWidth = canvas.width;
    const oldCanvasHeight = canvas.height;
    const widthRatio = oldCanvasWidth / svg.width / 4;
    const heightRatio = oldCanvasHeight / svg.height / 4;
    const ratio = Math.min(widthRatio, heightRatio);

    /**
     * Scale SVG image to maximum as it can
     */
    svg.scaleToWidth(svg.width * ratio);
    const offset = 50;
    const { getRandomInt } = fabric.util;
    const coord = {
      left: getRandomInt(0, canvas.getWidth() - getRandomInt(0, offset)),
      top: getRandomInt(0, canvas.getHeight() - getRandomInt(0, offset))
    };
    svg.set(coord);
    canvas.add(svg);
    canvas.requestRenderAll();
  });
};

export const addSVGContainerManyItem = ({ url, canvas }) => {
  fabric.loadSVGFromURL(url, function(objects, options) {
    if (!objects) {
      // Invalid SVG file
      return false;
    }

    /**
     * Group all objects into one for scaling
     */
    const svg = fabric.util.groupSVGElements(objects, options);

    const oldCanvasWidth = canvas.width;
    const oldCanvasHeight = canvas.height;

    const widthRatio = oldCanvasWidth / svg.width / 4;
    const heightRatio = oldCanvasHeight / svg.height / 4;
    const ratio = Math.min(widthRatio, heightRatio);

    /**
     * Scale SVG image to maximum as it can
     */
    svg.scaleToWidth(svg.width * ratio);

    /**
     * Ungroup all objects
     */
    svg._restoreObjectsState();
    const items = svg._objects;

    for (let index = 0; index < items.length; index++) {
      const element = items[index];
      canvas.add(element);
    }
    canvas.requestRenderAll();
  });
};

export const initAligningGuidelines = ({ canvas }) => {
  var ctx = canvas.getSelectionContext(),
    aligningLineOffset = 5,
    aligningLineMargin = 4,
    aligningLineWidth = 1,
    aligningLineColor = 'rgb(0,255,0)',
    viewportTransform,
    zoom = 1;

  function drawVerticalLine(coords) {
    drawLine(
      coords.x + 0.5,
      coords.y1 > coords.y2 ? coords.y2 : coords.y1,
      coords.x + 0.5,
      coords.y2 > coords.y1 ? coords.y2 : coords.y1
    );
  }

  function drawHorizontalLine(coords) {
    drawLine(
      coords.x1 > coords.x2 ? coords.x2 : coords.x1,
      coords.y + 0.5,
      coords.x2 > coords.x1 ? coords.x2 : coords.x1,
      coords.y + 0.5
    );
  }

  function drawLine(x1, y1, x2, y2) {
    /**
     * This is new logic
     * Source: https://stackoverflow.com/questions/62906060/fabric-js-snapping-guidelines-not-correctly-positioned-when-zoomed
     */
    /* var originXY = fabric.util.transformPoint(
        new fabric.Point(x1, y1),
        canvas.viewportTransform
      ),
      dimensions = fabric.util.transformPoint(
        new fabric.Point(x2, y2),
        canvas.viewportTransform
      );
    ctx.save();
    ctx.lineWidth = aligningLineWidth;
    ctx.strokeStyle = aligningLineColor;
    ctx.beginPath();

    ctx.moveTo(originXY.x, originXY.y);

    ctx.lineTo(dimensions.x, dimensions.y);
    ctx.stroke();
    ctx.restore();*/

    /**
     * Below is old and original logic
     * Source: https://github.com/fabricjs/fabric.js/blob/master/lib/aligning_guidelines.js
     */
    ctx.save();
    ctx.lineWidth = aligningLineWidth;
    ctx.strokeStyle = aligningLineColor;
    ctx.beginPath();
    ctx.moveTo(
      (x1 + viewportTransform[4]) * zoom,
      (y1 + viewportTransform[5]) * zoom
    );
    ctx.lineTo(
      (x2 + viewportTransform[4]) * zoom,
      (y2 + viewportTransform[5]) * zoom
    );
    ctx.stroke();
    ctx.restore();
  }

  function isInRange(value1, value2) {
    value1 = Math.round(value1);
    value2 = Math.round(value2);
    for (
      var i = value1 - aligningLineMargin, len = value1 + aligningLineMargin;
      i <= len;
      i++
    ) {
      if (i === value2) {
        return true;
      }
    }
    return false;
  }

  var verticalLines = [],
    horizontalLines = [];

  canvas.on('mouse:down', function() {
    viewportTransform = canvas.viewportTransform;
    zoom = canvas.getZoom();
  });

  canvas.on('object:moving', function(e) {
    var activeObject = e.target,
      canvasObjects = canvas.getObjects(),
      activeObjectCenter = activeObject.getCenterPoint(),
      activeObjectLeft = activeObjectCenter.x,
      activeObjectTop = activeObjectCenter.y,
      activeObjectBoundingRect = activeObject.getBoundingRect(),
      activeObjectHeight =
        activeObjectBoundingRect.height / viewportTransform[3],
      activeObjectWidth = activeObjectBoundingRect.width / viewportTransform[0],
      horizontalInTheRange = false,
      verticalInTheRange = false,
      transform = canvas._currentTransform;

    if (!transform) return;

    // It should be trivial to DRY this up by encapsulating (repeating) creation of x1, x2, y1, and y2 into functions,
    // but we're not doing it here for perf. reasons -- as this a function that's invoked on every mouse move

    for (var i = canvasObjects.length; i--; ) {
      if (canvasObjects[i] === activeObject) continue;

      var objectCenter = canvasObjects[i].getCenterPoint(),
        objectLeft = objectCenter.x,
        objectTop = objectCenter.y,
        objectBoundingRect = canvasObjects[i].getBoundingRect(),
        objectHeight = objectBoundingRect.height / viewportTransform[3],
        objectWidth = objectBoundingRect.width / viewportTransform[0];

      // snap by the horizontal center line
      if (isInRange(objectLeft, activeObjectLeft)) {
        verticalInTheRange = true;
        verticalLines.push({
          x: objectLeft,
          y1:
            objectTop < activeObjectTop
              ? objectTop - objectHeight / 2 - aligningLineOffset
              : objectTop + objectHeight / 2 + aligningLineOffset,
          y2:
            activeObjectTop > objectTop
              ? activeObjectTop + activeObjectHeight / 2 + aligningLineOffset
              : activeObjectTop - activeObjectHeight / 2 - aligningLineOffset
        });
        activeObject.setPositionByOrigin(
          new fabric.Point(objectLeft, activeObjectTop),
          'center',
          'center'
        );
      }

      // snap by the left edge
      if (
        isInRange(
          objectLeft - objectWidth / 2,
          activeObjectLeft - activeObjectWidth / 2
        )
      ) {
        verticalInTheRange = true;
        verticalLines.push({
          x: objectLeft - objectWidth / 2,
          y1:
            objectTop < activeObjectTop
              ? objectTop - objectHeight / 2 - aligningLineOffset
              : objectTop + objectHeight / 2 + aligningLineOffset,
          y2:
            activeObjectTop > objectTop
              ? activeObjectTop + activeObjectHeight / 2 + aligningLineOffset
              : activeObjectTop - activeObjectHeight / 2 - aligningLineOffset
        });
        activeObject.setPositionByOrigin(
          new fabric.Point(
            objectLeft - objectWidth / 2 + activeObjectWidth / 2,
            activeObjectTop
          ),
          'center',
          'center'
        );
      }

      // snap by the right edge
      if (
        isInRange(
          objectLeft + objectWidth / 2,
          activeObjectLeft + activeObjectWidth / 2
        )
      ) {
        verticalInTheRange = true;
        verticalLines.push({
          x: objectLeft + objectWidth / 2,
          y1:
            objectTop < activeObjectTop
              ? objectTop - objectHeight / 2 - aligningLineOffset
              : objectTop + objectHeight / 2 + aligningLineOffset,
          y2:
            activeObjectTop > objectTop
              ? activeObjectTop + activeObjectHeight / 2 + aligningLineOffset
              : activeObjectTop - activeObjectHeight / 2 - aligningLineOffset
        });
        activeObject.setPositionByOrigin(
          new fabric.Point(
            objectLeft + objectWidth / 2 - activeObjectWidth / 2,
            activeObjectTop
          ),
          'center',
          'center'
        );
      }

      // snap by the vertical center line
      if (isInRange(objectTop, activeObjectTop)) {
        horizontalInTheRange = true;
        horizontalLines.push({
          y: objectTop,
          x1:
            objectLeft < activeObjectLeft
              ? objectLeft - objectWidth / 2 - aligningLineOffset
              : objectLeft + objectWidth / 2 + aligningLineOffset,
          x2:
            activeObjectLeft > objectLeft
              ? activeObjectLeft + activeObjectWidth / 2 + aligningLineOffset
              : activeObjectLeft - activeObjectWidth / 2 - aligningLineOffset
        });
        activeObject.setPositionByOrigin(
          new fabric.Point(activeObjectLeft, objectTop),
          'center',
          'center'
        );
      }

      // snap by the top edge
      if (
        isInRange(
          objectTop - objectHeight / 2,
          activeObjectTop - activeObjectHeight / 2
        )
      ) {
        horizontalInTheRange = true;
        horizontalLines.push({
          y: objectTop - objectHeight / 2,
          x1:
            objectLeft < activeObjectLeft
              ? objectLeft - objectWidth / 2 - aligningLineOffset
              : objectLeft + objectWidth / 2 + aligningLineOffset,
          x2:
            activeObjectLeft > objectLeft
              ? activeObjectLeft + activeObjectWidth / 2 + aligningLineOffset
              : activeObjectLeft - activeObjectWidth / 2 - aligningLineOffset
        });
        activeObject.setPositionByOrigin(
          new fabric.Point(
            activeObjectLeft,
            objectTop - objectHeight / 2 + activeObjectHeight / 2
          ),
          'center',
          'center'
        );
      }

      // snap by the bottom edge
      if (
        isInRange(
          objectTop + objectHeight / 2,
          activeObjectTop + activeObjectHeight / 2
        )
      ) {
        horizontalInTheRange = true;
        horizontalLines.push({
          y: objectTop + objectHeight / 2,
          x1:
            objectLeft < activeObjectLeft
              ? objectLeft - objectWidth / 2 - aligningLineOffset
              : objectLeft + objectWidth / 2 + aligningLineOffset,
          x2:
            activeObjectLeft > objectLeft
              ? activeObjectLeft + activeObjectWidth / 2 + aligningLineOffset
              : activeObjectLeft - activeObjectWidth / 2 - aligningLineOffset
        });
        activeObject.setPositionByOrigin(
          new fabric.Point(
            activeObjectLeft,
            objectTop + objectHeight / 2 - activeObjectHeight / 2
          ),
          'center',
          'center'
        );
      }
    }

    if (!horizontalInTheRange) {
      horizontalLines.length = 0;
    }

    if (!verticalInTheRange) {
      verticalLines.length = 0;
    }
  });

  canvas.on('before:render', function() {
    if (canvas.contextTop) {
      canvas.clearContext(canvas.contextTop);
    }

    // canvas.clearContext(canvas.contextTop);
  });

  canvas.on('after:render', function() {
    for (var i = verticalLines.length; i--; ) {
      drawVerticalLine(verticalLines[i]);
    }
    for (var i = horizontalLines.length; i--; ) {
      drawHorizontalLine(horizontalLines[i]);
    }

    verticalLines.length = horizontalLines.length = 0;
  });

  canvas.on('mouse:up', function() {
    verticalLines.length = horizontalLines.length = 0;
    canvas.renderAll();
  });
};

export const initCenteringGuidelines = ({ canvas }) => {
  var canvasWidth = canvas.getWidth(),
    canvasHeight = canvas.getHeight(),
    canvasWidthCenter = canvasWidth / 2,
    canvasHeightCenter = canvasHeight / 2,
    canvasWidthCenterMap = {},
    canvasHeightCenterMap = {},
    centerLineMargin = 4,
    centerLineColor = 'rgba(255,0,241,0.5)',
    centerLineWidth = 1,
    ctx = canvas.getSelectionContext(),
    viewportTransform;

  for (
    var i = canvasWidthCenter - centerLineMargin,
      len = canvasWidthCenter + centerLineMargin;
    i <= len;
    i++
  ) {
    canvasWidthCenterMap[Math.round(i)] = true;
  }
  for (
    var i = canvasHeightCenter - centerLineMargin,
      len = canvasHeightCenter + centerLineMargin;
    i <= len;
    i++
  ) {
    canvasHeightCenterMap[Math.round(i)] = true;
  }

  function showVerticalCenterLine() {
    showCenterLine(
      canvasWidthCenter + 0.5,
      0,
      canvasWidthCenter + 0.5,
      canvasHeight
    );
  }

  function showHorizontalCenterLine() {
    showCenterLine(
      0,
      canvasHeightCenter + 0.5,
      canvasWidth,
      canvasHeightCenter + 0.5
    );
  }

  function showCenterLine(x1, y1, x2, y2) {
    var originXY = fabric.util.transformPoint(
        new fabric.Point(x1, y1),
        canvas.viewportTransform
      ),
      dimensions = fabric.util.transformPoint(
        new fabric.Point(x2, y2),
        canvas.viewportTransform
      );

    ctx.save();
    ctx.lineWidth = centerLineWidth;
    ctx.strokeStyle = centerLineColor;
    ctx.beginPath();

    ctx.moveTo(originXY.x, originXY.y);

    ctx.lineTo(dimensions.x, dimensions.y);
    ctx.stroke();
    ctx.restore();

    /* ctx.save();
    ctx.strokeStyle = centerLineColor;
    ctx.lineWidth = centerLineWidth;
    ctx.beginPath();
    ctx.moveTo(x1 * viewportTransform[0], y1 * viewportTransform[3]);
    ctx.lineTo(x2 * viewportTransform[0], y2 * viewportTransform[3]);
    ctx.stroke();
    ctx.restore(); */
  }

  var afterRenderActions = [],
    isInVerticalCenter,
    isInHorizontalCenter;

  canvas.on('mouse:down', function() {
    viewportTransform = canvas.viewportTransform;
  });

  canvas.on('object:moving', function(e) {
    var object = e.target,
      objectCenter = object.getCenterPoint(),
      transform = canvas._currentTransform;

    if (!transform) return;

    (isInVerticalCenter = Math.round(objectCenter.x) in canvasWidthCenterMap),
      (isInHorizontalCenter =
        Math.round(objectCenter.y) in canvasHeightCenterMap);

    if (isInHorizontalCenter || isInVerticalCenter) {
      object.setPositionByOrigin(
        new fabric.Point(
          isInVerticalCenter ? canvasWidthCenter : objectCenter.x,
          isInHorizontalCenter ? canvasHeightCenter : objectCenter.y
        ),
        'center',
        'center'
      );
    }
  });

  canvas.on('before:render', function() {
    if (canvas.contextTop) {
      canvas.clearContext(canvas.contextTop);
    }
  });

  canvas.on('after:render', function() {
    if (isInVerticalCenter) {
      showVerticalCenterLine();
    }
    if (isInHorizontalCenter) {
      showHorizontalCenterLine();
    }
  });

  canvas.on('mouse:up', function() {
    // clear these values, to stop drawing guidelines once mouse is up
    isInVerticalCenter = isInHorizontalCenter = null;
    canvas.renderAll();
  });
};

export const exportToSVGDataURL = ({ canvas }) => {
  let svgOutput = canvas.toSVG({
    suppressPreamble: true
  });

  /**
   * Reference: https://stackoverflow.com/questions/23218174/
   */
  if (!svgOutput.match(/^<svg[^>]+xmlns="http\:\/\/www\.w3\.org\/2000\/svg"/)) {
    svgOutput = svgOutput.replace(
      /^<svg/,
      '<svg xmlns="http://www.w3.org/2000/svg"'
    );
  }
  if (!svgOutput.match(/^<svg[^>]+"http\:\/\/www\.w3\.org\/1999\/xlink"/)) {
    svgOutput = svgOutput.replace(
      /^<svg/,
      '<svg xmlns:xlink="http://www.w3.org/1999/xlink"'
    );
  }

  //add xml declaration
  svgOutput = `<?xml version="1.0" standalone="no"?>\r\n${svgOutput}`;

  const url = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(
    svgOutput
  )}`;

  return url;
};

export const deleteObject = e => {
  const canvas = e.target.canvas;
  canvas.remove(e.target);
  canvas.requestRenderAll();
};

export const cloneObject = e => {
  const canvas = e.target.canvas;
  e.target.clone(function(cloned) {
    cloned.left += 10;
    cloned.top += 10;
    canvas.add(cloned);
  });
};

export const editObject = (e, events) => {
  e.target.editable = true;
  e.target.enterEditing();
  events.updateStatusEditMb(true);
};

export const copyObject = (e, events) => {
  events.onCopyObject();
};

export const pasteObject = (e, events) => {
  events.onPasteObject();
};

const renderIcon = icon => (ctx, left, top, _styleOverride, fabricObject) => {
  var size = 24;
  ctx.save();
  ctx.translate(left, top);
  ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
  ctx.drawImage(icon, -size / 2, -size / 2, size, size);
  ctx.restore();
};

/**
 * Init canvas with:
 * - Given ratio (width + height)
 * - controls
 * - center and align guidelines
 * - events
 * - canvasJSONData to restore canvas from a json string
 *
 * Example params
 * params = {
 *   id: '_v7804lou7',
 *   ratioData: {
 *     ratioWidth: 9,
 *     ratioHeight: 5
 *   }
 * }
 *
 * @param {object} param0
 * @returns {fabric.Canvas} canvas
 */
export const initCanvas = (
  { id, ratioData, events, checkMobile },
  canvasJSONData = ''
) => {
  const { ratioWidth, ratioHeight } = ratioData;

  // Object.keys(canvas).length > 0 && canvas.dispose();

  const canvas = new fabric.Canvas(id, {
    backgroundColor: '#ffffff',
    preserveObjectStacking: true,
    containerClass: `canvas-container`
  });
  let width = (window.CANVAS_WIDTH_PX * 80) / 100;
  let height = (width / ratioWidth) * ratioHeight;

  if (height > (window.CANVAS_HEIGTH_PX * 80) / 100) {
    height = (window.CANVAS_HEIGTH_PX * 80) / 100;
    width = (height / ratioHeight) * ratioWidth;
  }

  canvas.setDimensions({
    width,
    height
  });

  const deleteImg = document.createElement('img');
  deleteImg.src = deleteIcon;

  const cloneImg = document.createElement('img');
  cloneImg.src = cloneIcon;

  const editImg = document.createElement('img');
  editImg.src = editIcon;

  const copyImg = document.createElement('img');
  copyImg.src = copyIcon;

  const pasteImg = document.createElement('img');
  pasteImg.src = pasteIcon;

  fabric.Object.prototype.controls.deleteControl = new fabric.Control({
    x: 0.5,
    y: -0.5,
    offsetY: -16,
    offsetX: 16,
    cursorStyle: 'pointer',
    mouseUpHandler: (_eventData, e) => deleteObject(e),
    render: renderIcon(deleteImg),
    cornerSize: 24
  });

  fabric.Object.prototype.controls.clone = new fabric.Control({
    x: -0.5,
    y: -0.5,
    offsetY: -16,
    offsetX: -16,
    cursorStyle: 'pointer',
    mouseUpHandler: (_eventData, e) => cloneObject(e),
    render: renderIcon(cloneImg),
    cornerSize: 24
  });
  if (checkMobile) {
    fabric.Object.prototype.controls.edit = new fabric.Control({
      x: 0.5,
      y: 0.5,
      offsetY: 16,
      offsetX: 16,
      cursorStyle: 'pointer',
      mouseUpHandler: (_eventData, e) => editObject(e, events),
      render: renderIcon(editImg),
      cornerSize: 24
    });
  }

  // fabric.Object.prototype.controls.copy = new fabric.Control({
  //   x: -0.5,
  //   y: 0.5,
  //   offsetY: -16,
  //   offsetX: -16,
  //   cursorStyle: 'pointer',
  //   mouseUpHandler: (_eventData, e) => copyObject(e, events),
  //   render: renderIcon(copyImg),
  //   cornerSize: 24
  // });

  // fabric.Object.prototype.controls.paste = new fabric.Control({
  //   x: -0.435,
  //   y: 0.5,
  //   offsetY: 16,
  //   offsetX: -16,
  //   cursorStyle: 'pointer',
  //   mouseUpHandler: (_eventData, e) => pasteObject(e, events),
  //   render: renderIcon(pasteImg),
  //   cornerSize: 24
  // });

  // canvas.selection = false; // disable group selection
  // this.handleCanvasEvent();

  initCenteringGuidelines({ canvas });
  initAligningGuidelines({ canvas });

  if (events) {
    canvas.on({
      'mouse:down': events.onMouseDown({ id, canvas }),
      'mouse:dblclick': events.onDblClick({ id, canvas }),
      'selection:created': events.onSelectObject,
      'selection:updated': events.onSelectObject,
      'object:added': events.initUndoRedo,
      'object:removed': events.initUndoRedo
    });

    if (events.onKeydown) {
      // https://stackoverflow.com/questions/24684323/fabric-js-canvas-listen-for-keyboard-events
      const canvasWrapper = document.querySelector(
        `[data-canvas-id=${id}] .canvas-container`
      );

      canvasWrapper.tabIndex = 1000;
      canvasWrapper.addEventListener(
        'keydown',
        events.onKeydown(canvas),
        false
      );
    }
  }

  if (canvasJSONData) {
    canvas.loadFromJSON(canvasJSONData, () => {
      canvas.requestRenderAll();
    });
  }
  return canvas;
};
