const getStyle = ({ object, styleName }) => {
  const { getSelectionStyles, isEditing } = object;

  if (getSelectionStyles && isEditing) {
    return object.getSelectionStyles()[styleName];
  }

  return object[styleName];
};

const setStyle = ({ object, styleName, value }) => {
  if (!object) return;

  if (object.setSelectionStyles && object.isEditing) {
    object.setSelectionStyles({
      [styleName]: value
    });
  } else {
    object.set(styleName, value);
  }
};

export const getStyleFromObject = ({ object, styleName }) => {
  // elementHelpers
  if (!object || !styleName) {
    return '';
  }

  if (styleName === 'fontStyle') {
    const boldStr = getStyle({ object, styleName: 'fontWeight' });
    const italicStr = getStyle({ object, styleName: 'fontStyle' });
    const isUnderline = getStyle({ object, styleName: 'underline' });

    const result = {
      bold: boldStr === 'bold',
      italic: italicStr === 'italic',
      underline: isUnderline
    };

    return result;
  }

  return getStyle({ object, styleName });
};

export const setActiveObjectStyle = ({ object, styleName, value }) => {
  if (!object) return;

  let finalName = styleName;
  let finalValue = value;

  if (styleName === 'fontStyle') {
    const acceptedValues = ['bold', 'italic', 'underline'];

    if (acceptedValues.indexOf(value) === -1) {
      return;
    }

    const fontStyle = getStyleFromObject({ object, styleName });
    const { bold, italic, underline } = fontStyle;

    if (value === 'bold') {
      finalName = 'fontWeight';
      finalValue = bold ? 'normal' : 'bold';
    } else if (value === 'italic') {
      finalName = 'fontStyle';
      finalValue = italic ? 'normal' : 'italic';
    } else {
      finalName = 'underline';
      finalValue = underline ? '' : 'underline';
    }
  }

  setStyle({
    object,
    styleName: finalName,
    value: finalValue
  });
};

export const buildActiveObjectStyle = object => {
  const fontFamily = getStyleFromObject({
    object,
    styleName: 'fontFamily'
  });
  const fontSize = getStyleFromObject({
    object,
    styleName: 'fontSize'
  });
  const fill = getStyleFromObject({
    object,
    styleName: 'fill'
  });
  const stroke = getStyleFromObject({
    object,
    styleName: 'stroke'
  });
  const textAlign = getStyleFromObject({
    object,
    styleName: 'textAlign'
  });
  const fontStyle = getStyleFromObject({
    object,
    styleName: 'fontStyle'
  });
  const opacity = getStyleFromObject({
    object,
    styleName: 'opacity'
  });

  const activeObjectStyle = {
    fontFamily,
    fontSize,
    fill,
    stroke,
    textAlign,
    fontStyle,
    opacity
  };

  return activeObjectStyle;
};
