import requestApi from '@Service/RequestApiMiddlewareGuest';

const product = '/product';
const category = 'category';
const topic = 'topic';
const fonts = 'fonts';

export default {
  getElements() {
    return requestApi.get(`${category}/element`);
  },
  getCategories() {
    return requestApi.get(`${category}/template`);
  },
  getProductsByCategoryAndTopic(category, topic, perPage, page) {
    return requestApi.get(
      `${product}?category=${category}&topic=${topic}&perPage=${perPage}&page=${page}`
    );
  },
  getProductDetail(productId) {
    return requestApi.get(`${product}/${productId}`);
  },
  getFonts() {
    return requestApi.get(`${product}/${fonts}`);
  },
  getTopics() {
    return requestApi.get(`${topic}`);
  }
};
