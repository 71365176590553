import Vue from 'vue';
import VueScrollTo from 'vue-scrollto';
import VueGtag from 'vue-gtag';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebaseui/dist/firebaseui.css';
import Loading from 'vue-loading-overlay';
import VueToastr from 'vue-toastr';
import App from './App.vue';
import router from './router';
import store from './store';
import { ColorPicker, ColorPanel } from 'one-colorpicker';

// import new extension
import { BootstrapVueIcons, VBToggle, VBModal } from 'bootstrap-vue';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css';
import 'vue-loading-overlay/dist/vue-loading.css';
import './assets/css/fontawesome.min.css';
import './assets/css/solid.min.css';
import './assets/css/style.css';
import './assets/css/design.css';

if (process.env.VUE_APP_CUSTOM_NODE_ENV === 'production') {
  console.log = function() {};
  console.table = function() {};

  Vue.use(
    VueGtag,
    {
      config: { id: process.env.VUE_APP_GTAG },
      appName: process.env.VUE_APP_TITLE
    },
    router
  );
}
/**
 * Init Firebase UI
 */
const firebaseConfig = {
  apiKey: 'AIzaSyDee47NEf7rsgbW1-pVewzYfhUFCW3qHxg',
  authDomain: 'nitpen-57986.firebaseapp.com',
  databaseURL: 'https://nitpen-57986-default-rtdb.firebaseio.com',
  projectId: 'nitpen-57986',
  storageBucket: 'nitpen-57986.appspot.com',
  messagingSenderId: '593452868719',
  appId: '1:593452868719:web:954c7d5564b940d793999a',
  measurementId: 'G-SW1QH96C96'
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

Vue.config.productionTip = false;
Vue.use(BootstrapVueIcons);
Vue.use(Loading, {
  color: '#dc3545'
});
Vue.use(VueToastr, {
  defaultTimeout: 5000,
  defaultProgressBar: false,
  defaultStyle: { 'font-size': '18px', top: '50px' },
  defaultClassNames: ['fadeIn']
});
Vue.use(VueScrollTo, {
  container: 'body',
  duration: 500,
  easing: 'ease',
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
});

Vue.directive('b-toggle', VBToggle);
Vue.directive('b-modal', VBModal);
Vue.use(ColorPanel);
Vue.use(ColorPicker);

firebase.auth().onAuthStateChanged(user => {
  store.dispatch('fetchUser', user);
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');

Sentry.init({
  Vue: Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0
});
