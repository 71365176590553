import axios from 'axios';

import requestApiGuest from '@Service/RequestApiMiddlewareGuest';
import requestApiMember from '@Service/RequestApiMiddlewareMember';

const cart = '/cart';

export default {
  getCart() {
    return requestApiMember.get(`${cart}`);
  },
  getCartDetail(code) {
    return requestApiGuest.get(`${cart}/${code}`);
  },
  createCart(data = {}) {
    return requestApiMember.post(`${cart}`, data);
  },
  updateCart() {
    return requestApiGuest.put(`${cart}`);
  },
  deleteCart() {
    return requestApiGuest.delete(`${cart}`);
  },
  checkout(code) {
    return requestApiGuest.post(`${checkout}/${code}`);
  }
};
