import InformationRequest from './InformationRequest';
import ProductRequest from './ProductRequest';
import CustomerRequest from './CustomerRequest';
import CartRequest from './CartRequest';
import ContactRequest from './ContactRequest';

const repositories = {
  information: InformationRequest,
  products: ProductRequest,
  customers: CustomerRequest,
  carts: CartRequest,
  contact: ContactRequest
};

export const RequestFactory = {
  get: name => repositories[name]
};
