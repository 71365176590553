export const SET_WINDOW_SIZE = 'SET_WINDOW_SIZE';
export const SET_PRODUCT_CATEGORY_LIST = 'SET_PRODUCT_CATEGORY_LIST';
export const SET_PRODUCT_LIST_BY_CATEGORY = 'SET_PRODUCT_LIST_BY_CATEGORY';

export default {
  namespaced: true,
  state: () => ({
    productCategoryList: [],
    windowSize: {
      width: window.innerWidth,
      height: window.innerHeight
    },
    productListByCategory: {
      categorySlug: null,
      categoryId: null,
      categoryName: null,
      currentPage: null,
      perPage: null,
      totalRows: null,
      productList: []
    }
  }),
  mutations: {
    [SET_PRODUCT_CATEGORY_LIST](state, { productCategoryList }) {
      state.productCategoryList = productCategoryList;
    },
    [SET_WINDOW_SIZE](state, { width, height }) {
      state.windowSize.width = width;
      state.windowSize.height = height;
    },
    [SET_PRODUCT_LIST_BY_CATEGORY](state, data) {
      state.productListByCategory = data;
    }
  },
  getters: {}
};
