import { template } from 'lodash';

/**
 * Get default product when from given product array
 * @returns {object | undefined}
 */
export const getDefaultProduct = productList => {
  return productList.find(product => product.defaultSelected);
};

/**
 * Each product has many ratios, one of them should be default
 * Currently this is used for:
 * - Find default ratio in case reset ratio
 *
 * @param {object[]} productList array of products
 * @param {number} id product's id
 * @returns {object | undefined} product's ratio object or undefined
 */
export const getProductRatioById = ({ productList, id }) => {
  const product = productList.find(product => product.id === id);

  if (product) {
    return product.productSize.find(size => size.defaultSelected);
  }

  return undefined;
};

export const isOneSideTemplate = template => {
  if (template.details && template.details.length === 1) {
    return true;
  }

  return false;
};

export const getAllSVGURLsFromTemplate = template => {
  const { files } = template;

  if (!files || !files.length) {
    return [];
  }

  return files.map(f => f.url);
};

export const getAllSVGURLsFromDetails = template => {
  const { details } = template;

  if (!details || !details.length) {
    return [];
  }

  return details.map(f => f.url);
};
