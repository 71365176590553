import {
  RESPONSIVE_MOBILE,
  RESPONSIVE_TABLET,
  RESPONSIVE_SMALL_SCREEN
} from './constants';
const DEFAULT_LINE = '-';
export const genUniqueId = () => {
  return `_${Math.random()
    .toString(36)
    .substr(2, 9)}`;
};

export const dataURLtoFile = (dataURL, fileName = 'nitpen_file') => {
  let arr = dataURL.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], fileName, { type: mime });
};

export const onTrackClick = (id, gtag) => {
  if (process.env.VUE_APP_CUSTOM_NODE_ENV === 'production') {
    gtag.event('click', {
      event_label: id,
      value: true
    });
  }
};

export const isPC = store => {
  const { windowSize } = store.state.app;
  return Boolean(windowSize.width >= RESPONSIVE_TABLET);
};

export const isPCSmallScreen = store => {
  const { windowSize } = store.state.app;
  return Boolean(windowSize.width <= RESPONSIVE_SMALL_SCREEN);
};

export const isTablet = store => {
  const { windowSize } = store.state.app;
  return Boolean(windowSize.width < RESPONSIVE_TABLET);
};

export const isMobile = store => {
  const { windowSize } = store.state.app;
  return Boolean(
    windowSize.width < RESPONSIVE_MOBILE ||
      windowSize.height < RESPONSIVE_MOBILE
  );
};

export const isHorizontalScreen = store => {
  const { windowSize } = store.state.app;
  return windowSize.width < RESPONSIVE_TABLET
    ? Boolean(windowSize.width > windowSize.height + 150) // plus 150px for the keyboard when showing
    : Boolean(windowSize.width > windowSize.height);
};

export const isShowMainDesignArea = store => {
  const { activeRatio } = store.state.designModule;
  return !(!activeRatio.ratioWidth || !activeRatio.ratioHeight);
};

export const subStringProduct = param => {
  return [
    param.substring(0, param.lastIndexOf(DEFAULT_LINE)),
    param.substring(param.lastIndexOf(DEFAULT_LINE) + 1)
  ];
};

export const isEmpty = param => {
  return typeof param === 'undefined' || param === null || param === '';
};
