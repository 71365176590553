import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const appName = 'Nitpen';
const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@View/HomePage/HomePage.vue'),
    meta: {
      title: 'Trang chủ - ' + appName
    }
  },
  {
    path: '/thiet-ke/',
    name: 'design',
    component: () => import('@View/DesignPage/DesignPage.vue'),
    meta: {
      title: 'Thiết kế - ' + appName
    }
  },
  {
    path: '/thiet-ke/:product',
    name: 'product',
    component: () => import('@View/DesignPage/DesignPage.vue'),
    meta: {
      title: 'Thiết kế - ' + appName
    }
  },
  {
    path: '/san-pham/:category',
    name: 'category',
    component: () => import('@View/ProductPage/ProductPage.vue'),
    meta: {
      title: 'Sản phẩm' + ' - ' + appName
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.afterEach(to => {
  Vue.nextTick(() => {
    document.title = to.meta.title ? to.meta.title : 'default title';
  });
});

export default router;
