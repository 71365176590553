import requestApiGuest from '@Service/RequestApiMiddlewareGuest';
import requestApiMember from '@Service/RequestApiMiddlewareMember';

const register = '/register';
const login = '/login';
const logout = '/logout';
const forgotPassword = '/forgot-password';
const customer = '/customer';

export default {
  register() {
    return requestApiGuest.get(`${register}`);
  },
  login() {
    return requestApiGuest.get(`${login}`);
  },
  logout() {
    return requestApiMember.get(`${logout}`);
  },
  forgotPassword() {
    return requestApiGuest.get(`${forgotPassword}`);
  },
  getCustomer() {
    return requestApiMember.get(`${customer}`);
  },
  updateCustomer() {
    return requestApiMember.put(`${customer}`);
  }
};
