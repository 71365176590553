export const AUTO_SAVE_INTERVAL = 30000;
export const SCALE_RATIO = 1.2;
export const MAX_FILE_UPLOAD = 5;
export const KB_TO_MB = 1048576;
export const RESPONSIVE_SMALL_SCREEN = 1366;
export const RESPONSIVE_TABLET = 1024;
export const RESPONSIVE_MOBILE = 576;

export const CATEGORY_LIST = [
  { categoryName: 'Thời Trang', categoryId: 1 },
  { categoryName: 'Thức Ăn - Thức Uống', categoryId: 2 },
  { categoryName: 'Cửa Hàng', categoryId: 3 },
  { categoryName: 'Công Ty', categoryId: 4 },
  { categoryName: 'Du Lịch', categoryId: 5 },
  { categoryName: 'Giáo Dục', categoryId: 6 },
  { categoryName: 'Tình Nguyện', categoryId: 7 },
  { categoryName: 'Nghệ Thuật', categoryId: 8 },
  { categoryName: 'Trò Chơi', categoryId: 9 },
  { categoryName: 'Âm Nhạc', categoryId: 10 }
];

export const ZOOM_PERCENTS = [
  {
    key: '50%',
    value: -2
  },
  {
    key: '75%',
    value: -1
  },
  {
    key: '100%',
    value: 0
  },
  {
    key: '125%',
    value: 1
  },
  {
    key: '150%',
    value: 2
  },
  {
    key: '175%',
    value: 3
  },
  {
    key: '200%',
    value: 4
  },
  {
    key: '225%',
    value: 5
  },
  {
    key: '250%',
    value: 6
  }
  // {
  //   key: '275%',
  //   value: 7
  // },
  // {
  //   key: '300%',
  //   value: 8
  // }
];

export const FACEBOOK = 'https://www.facebook.com/NitpenDesignPlatform';
export const YOUTUBE = 'https://www.facebook.com/NitpenDesignPlatform';
export const MESSENGER = 'https://m.me/NitpenDesignPlatform';
export const ZALO = 'https://zalo.me/0345026032';
export const GG_FORM =
  'https://docs.google.com/forms/d/17U0TIKV6CcQqw7kceEKC6lkKcb2c8FGWv3BmT1BRp04/viewform?edit_requested=true';
